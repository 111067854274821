/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
  font-family: 'Nucleo Outline';
  src: url('../nucleo-outline.eot');
  src: url('../nucleo-outline.eot') format('embedded-opentype'),
    url('../nucleo-outline.woff2') format('woff2'),
    url('../nucleo-outline.woff') format('woff'),
    url('../nucleo-outline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.now-ui-icons {
  display: inline-block;
  font: normal normal normal 14px/1 'Nucleo Outline';
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.now-ui-icons.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}

.now-ui-icons.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.nc-icon-ul > li {
  position: relative;
}

.nc-icon-ul > li > .now-ui-icons {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}

.nc-icon-ul > li > .now-ui-icons.circle {
  top: -0.19047619em;
  left: -1.9047619em;
}

/*------------------------
  spinning icons
-------------------------*/

.now-ui-icons.spin {
  -webkit-animation: nc-icon-spin 2s infinite linear;
  -moz-animation: nc-icon-spin 2s infinite linear;
  animation: nc-icon-spin 2s infinite linear;
}

@-webkit-keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes nc-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes nc-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

/*------------------------
	font icons
-------------------------*/

.now-ui-icons.ui-1_check:before {
  content: '\ea22';
}

.now-ui-icons.ui-1_email-85:before {
  content: '\ea2a';
}

.now-ui-icons.arrows-1_cloud-download-93:before {
  content: '\ea21';
}

.now-ui-icons.arrows-1_cloud-upload-94:before {
  content: '\ea24';
}

.now-ui-icons.arrows-1_minimal-down:before {
  content: '\ea39';
}

.now-ui-icons.arrows-1_minimal-left:before {
  content: '\ea3a';
}

.now-ui-icons.arrows-1_minimal-right:before {
  content: '\ea3b';
}

.now-ui-icons.arrows-1_minimal-up:before {
  content: '\ea3c';
}

.now-ui-icons.arrows-1_refresh-69:before {
  content: '\ea44';
}

.now-ui-icons.arrows-1_share-66:before {
  content: '\ea4c';
}

.now-ui-icons.business_badge:before {
  content: '\ea09';
}

.now-ui-icons.business_bank:before {
  content: '\ea0a';
}

.now-ui-icons.business_briefcase-24:before {
  content: '\ea13';
}

.now-ui-icons.business_bulb-63:before {
  content: '\ea15';
}

.now-ui-icons.business_chart-bar-32:before {
  content: '\ea1e';
}

.now-ui-icons.business_chart-pie-36:before {
  content: '\ea1f';
}

.now-ui-icons.business_globe:before {
  content: '\ea2f';
}

.now-ui-icons.business_money-coins:before {
  content: '\ea40';
}

.now-ui-icons.clothes_tie-bow:before {
  content: '\ea5b';
}

.now-ui-icons.design_vector:before {
  content: '\ea61';
}

.now-ui-icons.design_app:before {
  content: '\ea08';
}

.now-ui-icons.design_bullet-list-67:before {
  content: '\ea14';
}

.now-ui-icons.design_image:before {
  content: '\ea33';
}

.now-ui-icons.design_palette:before {
  content: '\ea41';
}

.now-ui-icons.design_scissors:before {
  content: '\ea4a';
}

.now-ui-icons.design-2_html5:before {
  content: '\ea32';
}

.now-ui-icons.design-2_ruler-pencil:before {
  content: '\ea48';
}

.now-ui-icons.emoticons_satisfied:before {
  content: '\ea49';
}

.now-ui-icons.files_box:before {
  content: '\ea12';
}

.now-ui-icons.files_paper:before {
  content: '\ea43';
}

.now-ui-icons.files_single-copy-04:before {
  content: '\ea52';
}

.now-ui-icons.health_ambulance:before {
  content: '\ea07';
}

.now-ui-icons.loader_gear:before {
  content: '\ea4e';
}

.now-ui-icons.loader_refresh:before {
  content: '\ea44';
}

.now-ui-icons.location_bookmark:before {
  content: '\ea10';
}

.now-ui-icons.location_compass-05:before {
  content: '\ea25';
}

.now-ui-icons.location_map-big:before {
  content: '\ea3d';
}

.now-ui-icons.location_pin:before {
  content: '\ea47';
}

.now-ui-icons.location_world:before {
  content: '\ea63';
}

.now-ui-icons.media-1_album:before {
  content: '\ea02';
}

.now-ui-icons.media-1_button-pause:before {
  content: '\ea16';
}

.now-ui-icons.media-1_button-play:before {
  content: '\ea18';
}

.now-ui-icons.media-1_button-power:before {
  content: '\ea19';
}

.now-ui-icons.media-1_camera-compact:before {
  content: '\ea1c';
}

.now-ui-icons.media-2_note-03:before {
  content: '\ea3f';
}

.now-ui-icons.media-2_sound-wave:before {
  content: '\ea57';
}

.now-ui-icons.objects_diamond:before {
  content: '\ea29';
}

.now-ui-icons.objects_globe:before {
  content: '\ea2f';
}

.now-ui-icons.objects_key-25:before {
  content: '\ea38';
}

.now-ui-icons.objects_planet:before {
  content: '\ea46';
}

.now-ui-icons.objects_spaceship:before {
  content: '\ea55';
}

.now-ui-icons.objects_support-17:before {
  content: '\ea56';
}

.now-ui-icons.objects_umbrella-13:before {
  content: '\ea5f';
}

.now-ui-icons.education_agenda-bookmark:before {
  content: '\ea01';
}

.now-ui-icons.education_atom:before {
  content: '\ea0c';
}

.now-ui-icons.education_glasses:before {
  content: '\ea2d';
}

.now-ui-icons.education_hat:before {
  content: '\ea30';
}

.now-ui-icons.education_paper:before {
  content: '\ea42';
}

.now-ui-icons.shopping_bag-16:before {
  content: '\ea0d';
}

.now-ui-icons.shopping_basket:before {
  content: '\ea0b';
}

.now-ui-icons.shopping_box:before {
  content: '\ea11';
}

.now-ui-icons.shopping_cart-simple:before {
  content: '\ea1d';
}

.now-ui-icons.shopping_credit-card:before {
  content: '\ea28';
}

.now-ui-icons.shopping_delivery-fast:before {
  content: '\ea27';
}

.now-ui-icons.shopping_shop:before {
  content: '\ea50';
}

.now-ui-icons.shopping_tag-content:before {
  content: '\ea59';
}

.now-ui-icons.sport_trophy:before {
  content: '\ea5d';
}

.now-ui-icons.sport_user-run:before {
  content: '\ea60';
}

.now-ui-icons.tech_controller-modern:before {
  content: '\ea26';
}

.now-ui-icons.tech_headphones:before {
  content: '\ea31';
}

.now-ui-icons.tech_laptop:before {
  content: '\ea36';
}

.now-ui-icons.tech_mobile:before {
  content: '\ea3e';
}

.now-ui-icons.tech_tablet:before {
  content: '\ea58';
}

.now-ui-icons.tech_tv:before {
  content: '\ea5e';
}

.now-ui-icons.tech_watch-time:before {
  content: '\ea62';
}

.now-ui-icons.text_align-center:before {
  content: '\ea05';
}

.now-ui-icons.text_align-left:before {
  content: '\ea06';
}

.now-ui-icons.text_bold:before {
  content: '\ea0e';
}

.now-ui-icons.text_caps-small:before {
  content: '\ea1b';
}

.now-ui-icons.gestures_tap-01:before {
  content: '\ea5a';
}

.now-ui-icons.transportation_air-baloon:before {
  content: '\ea03';
}

.now-ui-icons.transportation_bus-front-12:before {
  content: '\ea17';
}

.now-ui-icons.travel_info:before {
  content: '\ea04';
}

.now-ui-icons.travel_istanbul:before {
  content: '\ea34';
}

.now-ui-icons.ui-1_bell-53:before {
  content: '\ea0f';
}

.now-ui-icons.ui-1_calendar-60:before {
  content: '\ea1a';
}

.now-ui-icons.ui-1_lock-circle-open:before {
  content: '\ea35';
}

.now-ui-icons.ui-1_send:before {
  content: '\ea4d';
}

.now-ui-icons.ui-1_settings-gear-63:before {
  content: '\ea4e';
}

.now-ui-icons.ui-1_simple-add:before {
  content: '\ea4f';
}

.now-ui-icons.ui-1_simple-delete:before {
  content: '\ea54';
}

.now-ui-icons.ui-1_simple-remove:before {
  content: '\ea53';
}

.now-ui-icons.ui-1_zoom-bold:before {
  content: '\ea64';
}

.now-ui-icons.ui-2_chat-round:before {
  content: '\ea20';
}

.now-ui-icons.ui-2_favourite-28:before {
  content: '\ea2b';
}

.now-ui-icons.ui-2_like:before {
  content: '\ea37';
}

.now-ui-icons.ui-2_settings-90:before {
  content: '\ea4b';
}

.now-ui-icons.ui-2_time-alarm:before {
  content: '\ea5c';
}

.now-ui-icons.users_circle-08:before {
  content: '\ea23';
}

.now-ui-icons.users_single-02:before {
  content: '\ea51';
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 0.1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.all-icons [class*='now-ui-icons'] {
  font-size: 32px;
}

.all-icons .font-icon-detail p {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #b8b8b8;
  padding: 0 10px;
  font-size: 0.7142em;
}