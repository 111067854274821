.register-page .full-page .info-horizontal .icon {
  margin-top: 0;
}
.register-page .full-page .info-horizontal {
  padding: 0 0 20px;
  text-align: left !important;
}
.pricing-page .full-page .description {
  margin-bottom: 65px;
}
