// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel {
  .carousel-inner {
    box-shadow: $box-shadow-raised;
  }
  .now-ui-icons {
    font-size: 2em;
  }
}
