@media screen and (max-width: 991px) {
  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  [data-notify="container"].alert {
    min-width: 400px;
  }

  #minimizeSidebar {
    display: none;
  }

  .timeline > li > .timeline-panel {
    width: 86% !important;
    float: right !important;
  }

  .timeline:before,
  .timeline > li > .timeline-badge {
    left: 5% !important;
  }

  .timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto !important;
  }

  .timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto !important;
  }

  .timeline > li:not(.timeline-inverted) > .timeline-panel:after,
  .timeline > li:not(.timeline-inverted) > .timeline-panel:before {
    @include rotate-180();
  }

  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 10px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .sidebar {
    box-shadow: none;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.now-ui-icons {
        opacity: 0.5;
      }
    }
  }

  @include sidebar();
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  // .navbar.fixed-top{
  //     width: $sidebar-width;
  //     right: 0;
  //     left: auto;
  // }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }

  .dropdown-menu .dropdown-item {
    color: inherit;
  }

  .footer {
    .copyright {
      float: right;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;

    .nav-item > .nav-link {
      margin-bottom: 5px;
    }
  }

  .user-profile [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }

  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }

  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }

  [data-notify="container"].alert {
    left: 10px !important;
    right: 10px !important;
    width: auto;
  }

  .card-contributions .card-stats {
    flex-direction: column;

    .bootstrap-switch {
      margin-bottom: 15px;
    }
  }

  .footer {
    .copyright {
      text-align: center;
    }
  }

  .section-nucleo-icons {
    .icons-container {
      i {
        font-size: 30px;

        &:nth-child(6) {
          font-size: 48px;
        }
      }
    }
  }

  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  @include sidebar();

  .navbar-minimize {
    display: none;
  }

  .sidebar {
    box-shadow: none;

    .nav-open & {
      box-shadow: $sidebar-box-shadow;
    }
  }

  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-overflow-scrolling: touch;
  }
}
